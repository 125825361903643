import React, {useMemo} from 'react';
import clsx from 'clsx';
import * as Routes from 'modules/routes';
import Tooltip from 'components/common/Tooltip';
import Card from '@/components/ui/Card';
import ActionsDropdown from '@/components/common/ActionsDropdown';
import { twMerge } from '@/utils';
import { IApiSlideShow } from '@/components/common/ProfessionalDevelopment/useProfessionalDevelopmentData';
import { ConditionalWrap } from 'components/common/Utils';
import { useModalManager } from '@/components/common/Modal';
import DownloadLink from '@/components/common/ProfessionalDevelopment/ProfessionalDevelopmentCard/DownloadLink';
import ConfirmResetModal
  from 'components/admin/SlideShow/PresentMode/Navigator/Controls/Settings/ResetSlideShowButton/ConfirmResetModal';
import useProfessionalDevelopmentResults
  from '@/components/common/ProfessionalDevelopment/useProfessionalDevelopmentResults';
import rewindIcon from '../../../../../images/icons/rewind.svg';
import certificateIcon from '../../../../../images/icons/certificate.svg';
import badgeIcon from '../../../../../images/icons/download-badge.svg';

interface ProfessionalDevelopmentCardProps {
  pdProgramId: number;
  programId: number;
  slideShow: IApiSlideShow;
  userId: number;
}

const ProfessionalDevelopmentCard = ({
  pdProgramId,
  programId,
  slideShow,
}: ProfessionalDevelopmentCardProps) => {
  const slideShowLink = Routes.present_mode_shared_slide_show_path(slideShow.id, {
    parent_id: slideShow.lesson_id,
    parent_type: 'Lesson',
    program_id: pdProgramId,
    referrer_program_id: programId,
  });

  const { data, isFetching, isRefetching } = useProfessionalDevelopmentResults({
    slideShowId: slideShow.id,
    programId: pdProgramId,
  });

  const loading = useMemo(() => isFetching && !isRefetching, [isFetching, isRefetching]);

  const hasAnsweredAllQuestions = !loading && data.slideShow.lesson?.professionalDevelopment?.completedForStaffer;
  const courseCompleted = hasAnsweredAllQuestions && slideShow.status === 'completed';

  const modalManager = useModalManager();

  return (
    <Card className="tw-w-60 tw-min-h-[218px] tw-relative tw-overflow-visible">
      <div className="tw-relative">
        <a
          href={slideShowLink}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Card.Image
            className="tw-max-w-full tw-object-contain tw-h-auto"
            src={slideShow.thumbnail_url}
            alt="Placeholder"
          />
        </a>

        {slideShow.status && slideShow.status !== 'not_started' && (
          <ConditionalWrap
            condition={slideShow.completed_at}
            wrap={children => (
              <Tooltip
                content={`Submitted on ${slideShow.completed_at}`}
                theme="white"
                size="medium"
              >
                <span>{children}</span>
              </Tooltip>
            )}
          >
            <span
              className={
                twMerge('tw-absolute tw-right-0 tw-bottom-0 tw-p-2 tw-rounded-tl tw-text-white tw-bg-dark-green',
                  slideShow.status === 'in_progress' && 'tw-bg-cyan-700')
              }
            >
              {slideShow.status.split('_').map(s => `${s.charAt(0).toUpperCase()}${s.slice(1)}`).join(' ')}
            </span>
          </ConditionalWrap>
        )}

      </div>
      <Card.Body className="tw-flex tw-flex-col tw-gap-2 tw-relative">
        <a
          className="tw-font-bold"
          href={slideShowLink}
          target="_blank"
          rel="noreferrer noopener"
        >
          {slideShow.title_en}
        </a>
        <p className="tw-text-[#555] tw-m-0">{slideShow.description_en}</p>
      </Card.Body>
      <div className="tw-flex tw-justify-between tw-items-end tw-p-2">
        <ActionsDropdown>
          <a
            className="dropdown-item tw-cursor-pointer tw-flex tw-items-center tw-gap-1.5"
            href={slideShowLink}
            target="_blank"
            rel="noreferrer noopener"
          >
            <span className="fa fa-play !tw-w-4 tw-flex tw-justify-center" />
            View Course
          </a>
          <DownloadLink
            courseCompleted={courseCompleted}
            dropdownIcon={certificateIcon}
            fileName={`${slideShow.title_en}_with_TCI_Certificate.pdf`}
            itemType="Certificate"
            itemUrl={Routes.pd_certificate_pdf_teacher_program_professional_developments_path(programId, { slide_show_id: slideShow.id })}
          />
          <DownloadLink
            courseCompleted={courseCompleted}
            dropdownIcon={badgeIcon}
            fileName={`${slideShow.title_en}_badge.jpg`}
            itemType="Badge"
            itemUrl={!loading && data.slideShow.courseBadgeUrl}
          />
          <div className="tw-relative">
            {slideShow.status === 'not_started' && (
              <Tooltip content="Please complete work in the course to restart it." interactive={false}>
                <span className="tw-absolute tw-inset-0" />
              </Tooltip>
            )}
            <button
              type="button"
              className="dropdown-item tw-cursor-pointer tw-flex tw-items-center tw-gap-1.5 tw-min-w-full"
              disabled={slideShow.status === 'not_started'}
              onClick={() => modalManager.open()}
              onKeyDown={() => modalManager.open()}
            >
              <img
                className={clsx('tw-w-4', {
                  'tw-opacity-30': slideShow.status === 'not_started',
                })}
                src={rewindIcon}
                alt="Certificate Icon"
              />
              Restart Course
            </button>
          </div>

        </ActionsDropdown>

        <div className="tw-text-gray-400 tw-italic">
          {slideShow.class_time && (
            <div className="tw-text-dark-grey">
              <i className="fa fa-clock-o" /> {slideShow.class_time}
            </div>
          )}
        </div>
      </div>

      {modalManager.isOpen && (
        <ConfirmResetModal
          activityType="professional_development"
          data={data}
          loading={loading}
          modalManager={modalManager}
          slideShowId={slideShow.id}
        />
      )}
    </Card>
  );
};

export default ProfessionalDevelopmentCard;
