import React from 'react';
import Axios from 'axios';
import I18n from 'i18n-js';
import * as Routes from '@/modules/routes';
import { useMutation } from '@tanstack/react-query';
import Modal, { Footer, StandardError } from '@/components/common/Modal';
import useSlideShowContext from '@/components/admin/SlideShow/stores/useSlideShowContext';
import { capitalizeWords } from '@/modules/TCIUtils';
import { PdSlideShowResults } from '../../../../../../../common/ProfessionalDevelopment/useProfessionalDevelopmentResults';

interface ConfirmResetModalProps {
  activityType: string;
  data: PdSlideShowResults;
  loading: boolean;
  modalManager: ModalManager;
  slideShowId: number;
}

const ConfirmResetModal = ({
  activityType,
  data,
  loading,
  modalManager,
}: ConfirmResetModalProps) => {
  const slideShowId = useSlideShowContext(state => state.slideShowId);
  const hasNotebookQuestions = useSlideShowContext(state => state.hasNotebookQuestions);

  // eslint-disable-next-line consistent-return
  const getPostRoute = () => {
    switch (activityType) {
      case 'professional_development':
        if (data.slideShow.pdStatus === 'in_progress') {
          return Routes.plato_api_reset_incomplete_professional_development_notebooks_path({
            lesson_id: data.slideShow.lesson.id,
          });
        }

        return Routes.plato_api_reset_professional_development_notebooks_path({
          exercise_result_id: data.slideShow.lesson.professionalDevelopment.stafferExerciseResult.id,
          lesson_id: data.slideShow.lesson.id,
        });
      case 'classroom_activity': case 'lesson_outline': case 'video_activity':
        return Routes.plato_api_reset_slide_show_slide_shows_path({ slide_show_id: slideShowId });
      default:
        break;
    }
  };

  const hasNotStarted = activityType === 'professional_development' && data.slideShow.pdStatus === 'not_started';

  const mutation = useMutation({
    mutationFn: () => Axios
      .post(getPostRoute())
      .then(() => window.location.reload()),
  });

  const modalMessage = () => {
    if (activityType === 'professional_development' && data != null) {
      return I18n.t('restart_professional_development',
        { slideshow_title: data.slideShow.lesson.titleWithPosition });
    }

    return (
      <div>
        <p>{I18n.t('restart_slide_show', { slide_show_type: capitalizeWords(activityType.replace('_', ' '))})}</p>

        {hasNotebookQuestions && (
        <p>
          <i className="fa fa-icon fa-warning tw-text-[#f69c05] tw-text-xl tw-mr-2" />
          {I18n.t('reading_notes_reset')}
        </p>
        )}
      </div>
    );
  };

  return (
    <Modal
      isOpen={modalManager.isOpen}
      closeModal={modalManager.close}
      headerText={I18n.t('restart_slide_show_header', { slide_show_type: capitalizeWords(activityType.replace('_', ' ')) })}
      footer={!loading && (
        <Footer
          primaryButtonCallback={mutation.mutate}
          primaryButtonSubmit={false}
          primaryButtonDisabled={mutation.isError || hasNotStarted}
          secondaryButtonCallback={modalManager.close}
          submitting={mutation.isLoading}
          wrapperClassName="tw-pr-4 tw-pb-4"
          primaryButtonClassName="btn btn--red"
          primaryButtonText={I18n.t('restart')}
        />
      )}
    >
      {mutation.isError ? (
        <StandardError />
      ) : (
        !loading && <div className="tw-leading-5">{modalMessage()}</div>
      )}
    </Modal>
  );
};

export default ConfirmResetModal;
