import React from 'react';
import I18n from 'i18n-js';

type LibraryCardProps = {
  titleTag: string;
  icon: React.ReactNode;
  path: string;
};

const LibraryCard = ({ titleTag, icon, path } : LibraryCardProps) => (
  <a href={path}>
    <div className="tw-h-[7rem] tw-w-[7rem] tw-flex-col tw-bg-white tw-rounded-lg tw-border-[1px] tw-border-solid
  tw-border-med-grey tw-shadow-xl tw-flex tw-justify-center tw-items-center"
    >
      <div className="tw-w-[50px] tw-h-[50px] tw-mb-2">
        {icon}
      </div>
      <p className="tw-text-tci-purple">
        {I18n.t(titleTag)}
      </p>

    </div>
  </a>
);

export default LibraryCard;
