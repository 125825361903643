import React from 'react';
import { LinkButton } from '@/components/ui/Buttons';
import { FilterCategory } from '@/components/common/libraries/common/Filter';
import Checkbox from '@/components/common/libraries/common/Checkbox';
import { TagType } from '@/components/shared/Correlations/types';
import useFilterData from './useFilterData';

interface FiltersProps {
  filterData: TagType[];
}

type FilterType = {
  value: number;
  label: string;
  checked: boolean;
};

const Filters = ({ filterData }: FiltersProps) => {
  const { filters, setFilters } = useFilterData({ filterData });
  const someChecked = (type: string) => filters[type].some((filter: FilterType) => filter.checked);
  const allChecked = (type: string) => filters[type].every((filter: FilterType) => filter.checked);

  const handleFilterChange = (type: string, value: string | number, checked: boolean) => {
    const oldFilters = filters[type];

    const newFilters = oldFilters.map(filter => (
      filter.value === value ? { ...filter, checked } : filter
    ));

    setFilters({ ...filters, [type]: newFilters });
  };

  const setAllFilters = (type: string, checked: boolean) => {
    const newFilters = filters[type].map(filter => ({ ...filter, checked }));

    setFilters({ ...filters, [type]: newFilters });
  };

  const clearAllFilters = () => {
    const newFilters = Object.keys(filters).reduce((acc, key) => {
      acc[key] = filters[key].map((filter: FilterType) => ({ ...filter, checked: false }));

      return acc;
    }, {});

    setFilters(newFilters);
  };

  const sortedFilters = () => {
    return Object.keys(filters).sort((a, b) => {
      // match digits after U, as all strings are in the format U<number>
      const matcher = /^U(\d+)/;

      const numA = parseInt(a.match(matcher)[1], 10);
      const numB = parseInt(b.match(matcher)[1], 10);

      return numA - numB;
    });
  };

  return (
    <div className="tw-sticky tw-top-1 tw-h-0 tw-flex-grow-0 tw-flex-shrink-0 tw-basis-60 tw-hidden">
      <div className="card tw-mr-3 tw-mt-3">
        <h1 className="tw-px-4 tw-mt-3">Filters</h1>
        <LinkButton
          className="tw-absolute tw-right-1 tw-top-5 tw-text-sm tw-cursor-pointer"
          onClick={clearAllFilters}
        >
          Clear
        </LinkButton>
        <hr className="tw-mx-2 tw-mt-1 tw-mb-2" />
        <FilterCategory
          filterClassName="tw-pl-4"
          className="tw-pr-4 tw-mb-1"
          shouldCollapse={false}
          triggerElement={<h1 className="tw-px-4 tw-mb-3 hover:tw-text-tci-purple">Unit</h1>}
        >
          {sortedFilters().map((filter) => {
            return (
              <FilterCategory
                key={filter}
                filterClassName="tw-pl-8"
                filters={filters[filter]}
                onChange={(value, checked) => {
                  handleFilterChange(filter, value, checked);
                }}
                shouldCollapse
                triggerElement={(
                  <Checkbox
                    extraInputClassName="hover:tw-outline hover:tw-border-black focus:tw-outline focus:tw-outline-1 focus:tw-outline-black"
                    extraClassName="tw-pb-2 tw-pl-4 tw-pr-4"
                    indeterminate={someChecked(filter) && !allChecked(filter)}
                    label={filter}
                    checked={allChecked(filter)}
                    onChange={(event) => {
                      setAllFilters(filter, event.target.checked);
                    }}
                  />
                )}
              />
            );
          })}
        </FilterCategory>
      </div>
    </div>
  );
};

export default Filters;
