import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { slideObjectPropTypes } from '../../../Utils';
import Slide from '../../../Slide';
import styles from '../../SlideShowBuilder.module.scss';
import checkIcon from '../../../../../../../images/icons/check.svg';
import { APPLY_TEMPLATE, GET_SLIDE_TEMPLATES } from '../../../Utils/slideShowGraphQL';
import useSlideShowContext from '../../../stores/useSlideShowContext';

const SlideTemplatePicker = ({ isModal, slide }) => {
  const [slideTemplates, setSlideTemplates] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [applyTemplateMutation] = useMutation(APPLY_TEMPLATE);
  const queryData = { fetchPolicy: 'network-only' };
  const {
    loading,
    data,
    error
  } = useQuery(GET_SLIDE_TEMPLATES, queryData);
  const updateSlide = useSlideShowContext(state => state.updateSlide);
  const lessonId = useSlideShowContext(state => state.lessonId);
  const unitId = useSlideShowContext(state => state.unitId);
  const programId = useSlideShowContext(state => state.programId);

  const parentId = lessonId || unitId;
  const parentType = lessonId ? 'Lesson' : 'Unit';

  useEffect(() => {
    if (error) {
      console.log(error);
      setHasError(true);
      return;
    }

    if (loading) return;
    if (!data) return;

    setSlideTemplates(data.slideTemplates);
  }, [data]);

  if (hasError) return ('Something went wrong. Please refresh the page and try again.');

  const applyTemplate = (templateId) => {
    applyTemplateMutation({
      variables: {
        modelId: parseInt(parentId.toString(), 10),
        modelType: parentType,
        programId,
        slideId: slide.id,
        slideTemplateId: templateId,
      },
    }).then((result) => {
      if (!result.data.applyTemplate.success) return console.log('Something went wrong.');

      updateSlide(slide.id, { ...result.data.applyTemplate.result });

      return null;
    }).catch((err) => {
      console.log(err);
    });
  };

  const renderTemplates = () => (
    slideTemplates.map((template) => {
      const templateObjects = template.slideTemplateObjects;

      const isTitleSlide = template.title.toLowerCase() === 'title slide';

      return (
        <button
          type="button"
          aria-label={template.title}
          className={styles.templateOption}
          style={{ display: isTitleSlide ? 'none' : undefined }}
          key={template.id}
          onClick={() => applyTemplate(template.id)}
        >
          <div className={styles.templateTitle}>
            {`${template.title}`}
            {parseInt(template.id, 10) === slide.slideTemplateId && (
              <img src={checkIcon} alt="Check icon" />
            )}
          </div>
          <Slide isModal={isModal} isTemplate templateObjects={templateObjects} />
        </button>
      );
    })
  );

  return (
    <div
      className={`${styles.templatePicker} ${isModal ? styles.templatePickerModal : ''}`}
    >
      {renderTemplates()}
    </div>
  );
};

SlideTemplatePicker.propTypes = {
  isModal: PropTypes.bool,
  slide: slideObjectPropTypes.isRequired,
};

SlideTemplatePicker.defaultProps = {
  isModal: false
};

export default SlideTemplatePicker;
